import Component from '../Component';
import { getBackgroundWithCover } from '../utils/styles';
import { getImagePathWithSize } from '../utils/core';
import { html } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
export class LkBanner extends Component {

  static get properties() {
    return {
      data: { type: String },
      backgroundCoverOpacity: { type: Number }
    }
  }

  constructor() {
    super();

    this.backgroundCoverOpacity = 0.4

  }

  render() {
    return html`
      <header .style="background-image: ${this.__getBackgroundImage(this.data)}">
      ${this.data.author ? html`
        <p>“${unsafeHTML(this.data.subtitle)}”</p>
        <p class="author">${this.data.author}</p>
        `: html`
        <p>${unsafeHTML(this.data.subtitle)}</p>
      `}
    `;
  }


  __getBackgroundImage(item) {
    const opacity = (!item.backgroundCoverOpacity && item.backgroundCoverOpacity !== 0) ? this.backgroundCoverOpacity : item.backgroundCoverOpacity
    return getBackgroundWithCover(getImagePathWithSize(item.img, 'banner'), opacity);
  }
}
customElements.define('lk-banner', LkBanner);