import OVERLAY_IMG from '../../images/internal/overlay.png';

export const getCoverAndOverlay = function (opacity = 0.4) {
  return `linear-gradient(0deg, rgba(0,0,0,${opacity}), rgba(0,0,0,${opacity})), linear-gradient(0deg, rgba(0,0,0,0.1), rgba(0,0,0,0.1)),	url('${OVERLAY_IMG}')`;
}

export const getBackgroundWithCover = function (imgUrl, opacity = 0.4) {
  return `linear-gradient(rgba(0, 0, 0, ${opacity}),rgba(0, 0, 0, ${opacity})),url(${imgUrl})`
}

export const getBackgroundWithCoverAndOverlay = function(img, opacity = 0.5) {
  return `linear-gradient(0deg, rgba(0,0,0,${opacity}), rgba(0,0,0,0.9)), url('${img}'),	linear-gradient(0deg, rgba(0,0,0,0.1), rgba(0,0,0,0.1)),	url('${OVERLAY_IMG}')`;
}