import { getBackgroundWithCover, getCoverAndOverlay } from '../utils/styles';

import Component from '../Component';
import { getImagePathWithSize } from '../utils/core';
import { html } from 'lit-element';

export class LkItems extends Component {

  constructor() {
    super();

    this.data = {};
    this.backgroundCoverOpacity = 0.4

  }


  /**
    * Object describing property-related metadata used by Polymer features
    */
  static get properties() {
    return {
      data: { type: Object },
      backgroundCoverOpacity: { type: Number }
    };
  }

  render() {
    return html`

      <header>
        <h1>${this.data.title}</h1>
        <p>${this.data.subtitle}</p>
      </header>
      <div class="container">
        ${this.data.items.map(item => html`
        <div class="item">
          <div class="overlay" style="opacity: ${this.__getImgOpacity(item)}"></div>
          <img src=${getImagePathWithSize(item.img, 'item')} alt=${item.name}/>
          <header>
            <h3>${item.name}</h3>
          </header>
        </div>
        `)}
      </div>
    `;
  }

  __getImgOpacity(item) {
    const opacity = (!item.backgroundCoverOpacity && item.backgroundCoverOpacity !== 0) ? this.backgroundCoverOpacity : item.backgroundCoverOpacity
    return opacity;
  }

  __getCover(item) {
    const opacity = (!item.backgroundCoverOpacity && item.backgroundCoverOpacity !== 0) ? this.backgroundCoverOpacity : item.backgroundCoverOpacity
    return getCoverAndOverlay(opacity);
  }
}
customElements.define('lk-items', LkItems);