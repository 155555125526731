import Component from '../Component';
import { getImagePathWithSize } from '../utils/core';
import { getPageLocation } from '../utils/data';
import { html } from 'lit-html';

export default class LKPagePreview extends Component {

  constructor() {
    super();

    this.addEventListener('click', ev => {
      if (this.data) {
        this._navigateTo(this.__ref(this.data))
      }
    })
  }

  static get properties() {
    return {
      data: {
        type: Object
      },
      featured: {
        type: Boolean
      }
    }
  }

  __ref({ pageId, pageType }) {
    return getPageLocation({
      pageId,
      pageType
    });
  }

  render() {
    return html`
      <div class="container">
        <div class="image" .style="background-image: url('${getImagePathWithSize(this.data.img, 'preview')}')"></div>
        <div class="content">
          <header>
            <h1>${this.data.title}</h1>
            <p>${this.data.description}</p>
          </header>
           <a href=${this.__ref(this.data)} class="button">Saber más</a>
        </div>
      </div>
    `;
  }
}

customElements.define('lk-page-preview', LKPagePreview);