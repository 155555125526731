import config from '../config';

const __dataTypes = {
  PAGE: 'pages',
  MODULE: 'modules'
}

export async function getPageData() {
  return __getContentData(__dataTypes.PAGE, getParamsFromLocation())
    .then(async data => {
      await Promise.all(data.modules
        .filter(item => item.dynamic)
        .map(__parseModuleData))
      return data
    });
}

const __getContentData = function (dataType,{ contentType, contentId }) {
  const url = `${config.services.base}/${dataType}/${contentType}${contentId ? `/${contentId}` : ''}.json`;
  //console.log(url)
  return __requestData(url);
}

const __requestData = function (url) {
  return fetch(url)
    .then(response => response.json())
    .then(myJson => myJson.data);
}

const __parseModuleData = async function (item) {
  item.data = await __getContentData(__dataTypes.MODULE, item);
  //console.log(item.data)
  return item;
}

export const getParamsFromLocation = function () {
  const params = document.location.pathname
    .substring(1)
    .split('/')

  let contentType = params[0] || 'home';
  let contentId = params[1] || '';

  return {
    contentType,
    contentId
  }
}

export const getPageLocation = function ({ pageId, pageType }) {
  return `/${pageType ? `${pageType}/` : ''}${ pageId ? `${pageId}` : '' }`
}

