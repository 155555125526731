import Component from '../Component';
import { html } from 'lit-html';

export default class LKFooter extends Component {

	render() {
		return html`
			<section>
				<h3>Email</h3>
				<p><a href="mailto:laukailash@gmail.com">laukailash@gmail.com</a></p>
			</section>
			<section>
				<h3>Social</h3>
				<ul class="icons alt">
					<li><a href="https://www.instagram.com/lau_kailash/" class="icon brands alt fa-instagram"><span class="label">Instagram</span></a></li>
				</ul>
			</section>
  `;
	}
}

customElements.define('lk-footer', LKFooter);