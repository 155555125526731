import Component from '../Component';
import { html } from 'lit-html';

class LKTable extends Component {

  constructor() {
    super();

    this.classList.add('table-wrapper');
    this.style.display = 'block';
  };

  static get properties() {
    return {
      data: {
        type: Object
      }
    }
  }

  render() {
    return html`
        <h1>${this.data.name}</h1>

        <div class="table">
        ${this.data.content.map(price => html`
          <div class="table__item">
            <span class="table__name">${price.name}</span>
            <p class="table__description">${price.description}</p>
            <span class="table__price"><strong>${price.price}€<strong></span>
          </div>
        `)}
        </div>
    `;
  }

}

customElements.define('lk-table', LKTable);