import Component from '../Component';
import { html } from 'lit-html';

export default class LKCopyright extends Component {

  render() {
    return html`
      <ul>
				<li><a href="https://html5up.net" target="blank">HTML5 UP</a></li>
				<li><a href="https://unsplash.com" target="blank">Unsplash</a></li>
				<li><a href="https://pexels.com" target="blank">Pexels</a></li>
			</ul>
  `;
  }
}

customElements.define('lk-copyright', LKCopyright);