import Component from '../Component';
import { html } from 'lit-html';

export default class LKLogo extends Component {

  render() {
    return html`
      <a href="/" class="logo">Lau Kailash</a>
  `;
  }
}

customElements.define('lk-logo', LKLogo);