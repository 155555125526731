import '../Component';
import './lk-intro';
import './lk-logo';
import './lk-main';
import './lk-nav';
import './lk-footer';
import './lk-copyright';
import './lk-loading';
import './lk-page';
import './lk-product';

import { LitElement, html } from 'lit-element';
import { getPageType, pageTypes } from '../utils/core';

import DEFAULT_BG from '../../images/internal/default-bg.jpg';
import config from '../config';
import { getBackgroundWithCoverAndOverlay } from '../utils/styles';
import { getImagePathWithSize } from '../utils/core';
import { getPageData } from '../utils/data';
import { installRouter } from 'pwa-helpers/router.js';

const scrollToElement = require('scroll-to-element');

export default class LKMain extends LitElement {

  constructor() {
    super();

    this.loading = true;
    this.data = {};

    this.__background = null;
    this.__firstLoad = true;

    this.style.display = 'block';

    this.addEventListener('navigate-request', ev => {
      window.history.pushState({}, '', ev.detail);
      this.load(window.location);
    })

    installRouter(location => this.load(location))
  }

  static get properties() {
    return {
      loading: {
        type: Boolean
      },
      data: {
        type: Object
      }
    }
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {
    this.__nav = this.querySelector('#nav');
    this.__background = this.querySelector('#background');
  }

  __renderContent() {
    switch (this.data.pageType) {
      case pageTypes.PRODUCT:
        return html`<lk-product id="main" .data=${this.data}></lk-product>`
      case pageTypes.PAGE:
      default:
        return html`<lk-page id="main" .data=${this.data}></lk-page>`
    }
  }

  async load(location) {
    // Mobile menu adds #navPanel to location, it is not needed a new call
    if (location.hash === '') {

      this.loading = true;
      this.__firstLoad ? this.__firstLoad = false : scrollToElement('lk-logo', { duration: 1 });

      await this.requestUpdate();

      Promise.all([
        getPageData(),
        //this.__loadDynamicModules()
        // get Generic Data (articles, etc)
      ]).then(async results => {
        //console.log(results[0]);
        this.loading = false;
        this.data = Object.assign({}, results[0])
        await this.updateComplete;
        this.__nav.updateNav();
        const bgImage = getImagePathWithSize(this.data.backgroundImage, 'bg') || DEFAULT_BG;
        this.__background.style.backgroundImage = getBackgroundWithCoverAndOverlay(bgImage, this.data.backgroundCoverOpacity);

        //New page view to Analytics
        gtag('event', 'page_view', {
          page_title: location.pathname.split('/')
                                       .pop(),
          page_location: location.href,
          page_path: location.pathname,
          send_to: config.analytics.id
        })
      })
    }
  }

  __loadDynamicModules() {
    switch (getPageType()) {
      case pageTypes.PAGE:
        return import(/* webpackChunkName: "detail" */ "./lk-page")
    }
  }

  render() {
    return html`
      <div id="wrapper" class="fade-in">
        <lk-intro id="intro"></lk-intro>
        <lk-logo id="header"></lk-logo>
        <lk-nav id="nav"></lk-nav>
        <main .loading=${this.loading}>
          ${this.loading ? html`<lk-loading>` : this.__renderContent()}
        </main>
        <lk-footer id="footer"></lk-footer>
        <lk-copyright id="copyright"></lk-copyright>
        <div id="background" class="bg fixed"></div>
      </div>
    `;
  }
}

customElements.define('lk-main', LKMain)