import { LitElement, html } from 'lit-element';

import Component from '../Component';

export class LkLoading extends Component {

  connectedCallback() {
    super.connectedCallback();

    this.__updateSize();
    window.addEventListener('resize', ev => this.__updateSize());
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('resize', ev => this.__updateSize());
  }

  __updateSize() {
    this.style.height = `${window.innerHeight}px`;
  }

  render() {
    return html`
      <div class="loader"></div>
    `
  }
}
customElements.define('lk-loading', LkLoading);