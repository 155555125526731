import Component from '../Component';
import { getImagePathWithSize } from '../utils/core';
import { html } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

export class LkInfoList extends Component {

  /**
    * Object describing property-related metadata used by Polymer features
    */
  static get properties() {
    return {
      data: { type: Object }
    };
  }

  render() {
    return html`
     <header>
      <h2>${this.data.title}</h2>
      ${this.data.description ? html`<p>${unsafeHTML(this.data.description)}</p>` : ''}
    </header>
      <div class="list">
        ${this.data.list.map(element => html`
        <div class="list__item card">
          <div class="list__back" .style="background-image: url('${getImagePathWithSize(element.img, 'thumb')}')"></div>  
          <header class="list__content">
              <h3>${element.name}</h3>
              <p class="list__description">${unsafeHTML(element.description)}</p>
          </header>
        </div>
        `)}
      </div>
    `;
  }
}
customElements.define('lk-info-list', LkInfoList);