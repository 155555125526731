import './lk-table';
import './lk-info-list';
import './lk-header';

import Component from '../Component';
import { contentTypes } from '../utils/core';
import { html } from 'lit-element';

export class LkProduct extends Component {

  static get properties() {
    return {
      data: { type: Object }
    };
  }

  render() {
    return html`
      <lk-header .data=${this.__getData(contentTypes.HEADER)}></lk-header>
      <lk-info-list .data=${this.__getData(contentTypes.INFO_LIST)}></lk-info-list>

      ${this.__getData(contentTypes.PRICES) ? html`
      <section>
      <h2>Precios</h2>
        ${this.__getData(contentTypes.PRICES).map(price => html`
          <lk-table .data=${price}></lk-table>
        `)}
      </section>
      ` : ''}
    `;
  }

  __getData(contentType, contentId) {
    const filterCondition = module => ((module.contentType === contentType) && (contentId ? module.contentId === contentId : true));
    const result = this.data.modules.filter(filterCondition);

    return result.length ? result[0].data : false;
  }
}

customElements.define('lk-product', LkProduct);