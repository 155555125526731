import './lk-page-preview';

import Component from '../Component';
import { html } from 'lit-html';

export default class LKPagePreviewList extends Component {

  constructor() {
    super();
  }

  static get properties() {
    return {
      data: {
        type: Object
      }
    }
  }

  ref(article) {
    return `/${[article.contentType]}/${article.id}`;
  }

  render() {
    return html`
      <header>
        <h1>${this.data.title}</h1>
        <p>${this.data.description}</p>
      </header>
      <ul>
        ${this.data.pages.map(page => html`
          <li><lk-page-preview class="card linkable" .data=${page}></lk-page-preview>
        `)}
      </ul>
  `;
  }
}

customElements.define('lk-page-preview-list', LKPagePreviewList);