import { getImagePathWithSize, removeHTML } from '../utils/core';

import Component from '../Component';
import { html } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

export class LkHeader extends Component {

  /**
    * Object describing property-related metadata used by Polymer features
    */
  static get properties() {
    return {
      data: { type: Object }
    };
  }

  render() {
    return html`
      <header class="major">
        <h2>${unsafeHTML(this.data.title)}</h2>
        <img src=${getImagePathWithSize(this.data.img, 'preview')} alt=${removeHTML(this.data.title)} />
        ${this.data.description ? html`${unsafeHTML(this.data.description)}` : ''}
      </header>
    `;
  }
}
customElements.define('lk-header', LkHeader);