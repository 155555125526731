import Component from '../Component';
import { html } from 'lit-html';

export default class LKIntro extends Component {

  constructor() {
    super();

    this.title1 = 'Inhala y alarga, crece.';
    this.title2 = 'Exhala y relaja, suelta.';
  }

  /**
    * Object describing property-related metadata used by Polymer features
    */
  static get properties() {
    return {
      title1: {
        type: String
      },
      title2: {
        type: String
      }
    };
  }

  render() {
    return html`
			<p>${this.title1}</p>
			<p>${this.title2}</p>
  `;
  }
}

customElements.define('lk-intro', LKIntro);