import { LitElement } from 'lit-element';

export default class Component extends LitElement{

  createRenderRoot() {
    return this;
  }

  _navigateTo(href) {
    this.dispatchEvent(new CustomEvent('navigate-request', {
      detail: href,
      bubbles: true,
      composed: true
    }));
  }

}