const config = {
  services: {
    base: '/data',
    prices: ''
  },
  analytics: {
    id: 'G-8KH1PRHQW2'
  }
}

export default config;