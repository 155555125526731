import Component from '../Component';
import { getImagePathWithSize } from '../utils/core';
import { getPageLocation } from '../utils/data';
import { html } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

export class LKProductPreview extends Component {

  static get properties() {
    return {
      data: Object
    }
  }

  constructor() {
    super();

    this.addEventListener('click', ev => {
      if (this.data && this.data.pageId) {
        this._navigateTo(this.__ref(this.data.pageId))
      }
    })
  }

  updated(changedProps) {
    if (this.data && this.data.pageId) {
      this.classList.add('linkable')
    }
  }


  render() {
    return html`
    <div class="container">
      <div class="image" .style="background-image: url('${getImagePathWithSize(this.data.img, 'thumb')}')"></div>
      <div class="content">
        <header>
          <h3>${this.data.name}</h3>
          <p>${this.data.description}</p>
        </header>
        <div class="info">
           <ul>
            <li>${unsafeHTML(this.data.schedule)}</li>
            <li>${this.data.duration}</li>
          </ul>
          ${this.data.pageId ? html`<a href=${this.__ref(this.data.pageId)} class="button">Saber más</a>` : ''}
        </div>
      </div>
    </div>
    `;
  }

  __ref(pageId) {
    return getPageLocation({
      pageId,
      pageType: 'product'
    });
  }
}
customElements.define('lk-product-preview', LKProductPreview);