import './lk-product-preview';

import Component from '../Component';
import { html } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

export class LkProductList extends Component {

  static get properties() {
    return {
      data: { type: Object }
    }
  }

  render() {
    return html`
    <header>
      <h2>${this.data.title}</h2>
      <p>${unsafeHTML(this.data.description)}</p>
    </header>
      <ul>
        ${this.data.products.map(product => html`
          <li><lk-product-preview class="card" .data=${product}></lk-product-preview>
        `)}
      </ul>
    `;
  }
}
customElements.define('lk-product-list', LkProductList);