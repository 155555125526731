import Component from '../Component';
import { getParamsFromLocation } from '../utils/data';
import { html } from 'lit-html';
export default class LKNav extends Component {


	static get properties() {
		return {
			current: { type: String }
		};
	}

	updateNav() {
		const data = getParamsFromLocation() || {};
		this.current = data.contentType;
	}

	render() {
		return html`
      <ul class="links">
				<li class=${this.__getActiveNavItem('yoga')}><a href="/yoga">Yoga</a></li>
				<li class=${this.__getActiveNavItem('empresas')}><a href="/empresas">Empresas</a></li>
				<li class=${this.__getActiveNavItem('talleres')}><a href="/talleres">Talleres</a></li>
				<li class=${this.__getActiveNavItem('sobre-mi')}><a href="/sobre-mi">Sobre mí</a></li>
			</ul>
			<ul class="icons">
				<li><a href="https://www.instagram.com/lau_kailash/" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
			</ul>
  `;
	}

	__getActiveNavItem(contentId) {
		return contentId === this.current ? 'active' : ''
	}
}

customElements.define('lk-nav', LKNav);