import './lk-prices';
import './lk-page-preview-list';
import './lk-page-preview';
import './lk-items';
import './lk-banner';
import './lk-product-list';
import './lk-header';
import './lk-info-list';

import Component from '../Component';
import { contentTypes } from '../utils/core';
import { html } from 'lit-html';

class LKPage extends Component {

  constructor() {
    super();

    this.style.display = 'block';

    this.data = {};
  };

  static get properties() {
    return {
      data: {
        type: Object
      }
    }
  }

  __renderModules() {

    return this.data.modules.map(item => {
      switch (item.contentType) {
        case contentTypes.PRICES: return html`<lk-prices .data=${item.data}></lk-prices>`
        case contentTypes.PAGE_PREVIEW_LIST: return html`<lk-page-preview-list .data=${item.data}></lk-page-preview-list>`
        case contentTypes.PAGE_PREVIEW: return html`<lk-page-preview featured .data=${item.data}></lk-page-preview>`
        case contentTypes.ITEM_LIST: return html`<lk-items .data=${item.data}></lk-items>`
        case contentTypes.BANNER: return html`<lk-banner .data=${item.data}></lk-banner>`
        case contentTypes.PRODUCT_LIST: return html`<lk-product-list .data=${item.data}></lk-product-list>`
        case contentTypes.HEADER: return html`<lk-header .data=${item.data}></lk-header>`
        case contentTypes.INFO_LIST: return html`<lk-info-list .data=${item.data}></lk-info-list>`
      }
    })
  }

  render() {
    return html`
      ${this.__renderModules()}
    `;
  }
}

customElements.define('lk-page', LKPage);