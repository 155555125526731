export const pageTypes = {
  PAGE: 'page',
  PRODUCT: 'product'
};

export const contentTypes = {
  PRICES: 'prices',
  ARTICLE: 'article',
  PAGE_PREVIEW_LIST: 'page-preview-list',
  PAGE_PREVIEW: 'page-preview',
  HTML: 'html',
  INFO: 'info',
  ITEM_LIST: 'items',
  BANNER: 'banner',
  PRODUCT_LIST: 'product-list',
  PRICES: 'prices',
  HEADER: 'header',
  INFO_LIST: 'info-list'
};

export const getImagePathWithSize = function (imgObject, forceSize) {

  if (!imgObject) return false;

  const isSimple = typeof (imgObject) === 'string';

  if (isSimple) {
    if (forceSize) {
      return __getImagePathWithSize({ size: forceSize, fileName: imgObject });
    } else {
      return imgObject;
    }
  } else {
    if (imgObject.size) {
      return __getImagePathWithSize(imgObject);
    } else {
      return __getImagePathWithSize({ size: forceSize, fileName: imgObject.fileName });
    }
  }
};

const __getImagePathWithSize = function ({ size, fileName }) {
  return size ? fileName.split('.').join(`_${size}.`) : fileName;
}

export const getPageType = function () {
  return (window.location.pathname.split('/')[1] === 'product') ? pageTypes.PRODUCT : pageTypes.PAGE;
}

export const removeHTML = function (str = '') {
  return str.replace(/(<([^>]+)>)/ig, '');
}