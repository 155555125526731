import './lk-table';

import Component from '../Component';
import { html } from 'lit-element';

/**
 * `LowerCaseDashedName` Description
 *
 * @customElement
 * @polymer
 * @demo
 * 
 */
class LKPrices extends Component {
  
  constructor() {
    super();
    this.style.display = 'block';
  }
  
  static get properties() {
    return {
      data: {
        type: Object
      }
    }
  }

  /**
   * Implement to describe the element's DOM using lit-html.
   * Use the element current props to return a lit-html template result
   * to render into the element.
   */
  render() {
    return html`
      <header class="major">
        <h2>PRECIOS</h2>
        <p>Estos son los precios chachis</p>
      </header>

      ${this.data.prices.map(price => html`
        <lk-table .data=${price}></lk-table>
      `)}
    `;
  }

}

customElements.define('lk-prices', LKPrices);